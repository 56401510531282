import React from "react"
import styled from "@emotion/styled"

const Break = () => {
  const styleBreak = {
    flexBasis: `100%`,
    height: `0`,
  }

  const Break = styled.div`
    ${styleBreak}
  `
  return <Break />
}

export default Break
