import React from "react"
import Break from "../../shared/Break"
import Section from "../../shared/Section"
import PostPreview from "../../shared/PostPreview"
import StyledLink from "../../shared/StyledLink"

const FromTheBlog = ({ blogPosts }) => {
  return (
    <Section
      title="Latest Insights from our Team"
      subtitle="Read the latest on industry news, as well as a few of our own case studies."
      id="FromTheBlog"
    >
      {blogPosts.map(({ node: blogPost }) => (
        <div key={blogPost.id}>
          <PostPreview
            title={blogPost.title}
            content={blogPost.body.childMarkdownRemark.plainText.trunc(
              200,
              true
            )}
            url={`/blogpost/${blogPost.slug}`}
            image={blogPost.image}
            publishDate={blogPost.createdAt}
          />
        </div>
      ))}
      {blogPosts.length > 3 && (
        <>
          <Break />
          <StyledLink isPrimary to="/blogposts/">
            View More
          </StyledLink>
        </>
      )}
    </Section>
  )
}

export default FromTheBlog
