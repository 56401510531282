import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { colors } from "../../../assets/themes/default"
import Box from "../../shared/Box"
import Section from "../../shared/Section"
import styled from "@emotion/styled"

const WhatsYourStruggle = () => {
  const styleAnchorLink = {
    textDecoration: `none`,
  }

  const StyledAnchorLink = styled(AnchorLink)`
    ${styleAnchorLink}
  `

  const content = [
    {
      title: "Not receiving enough sales or leads from current traffic?",
      description:
        "A variety of reasons may be the cause but our team will work with you to assist in increasing your traffic and optimizing engagement.",
    },
    {
      title: "Traffic is not converting to leads?",
      description:
        "The traffic you are receiving may not be the optimal traffic for your business.  Let us help you identify and optimize traffic across a number of digital marketing channels.",
    },
    {
      title: "Your site is not showing up on Google?",
      description:
        "If your business does not appear on a simple Google search, opportunities are being left on the table. Many options exist to not only achieve this but also optimize using both organic and paid mediums.",
    },
    {
      title: "Unsure of what exactly your current agency is doing?",
      description:
        "Our methodology and processes include access to a dedicated dashboard showing a transparent view of the value being added to your business.",
    },
  ]

  return (
    <Section
      bgImage={"images/stripes-light.png"}
      bgImageRepeat
      id="WhatsYourStruggle"
      isFullWidth
      title="What's your business struggling with?"
    >
      {content.map((item, index) => {
        return (
          <StyledAnchorLink
            to={`/#WhatWeDo`}
            title={`What we do?`}
            stripHash
            key={index}
          >
            <Box
              bgColor={colors.blue}
              bgColorHighlight={colors.blueHigh}
              description={item.description}
              hasShadow
              textColor={colors.white}
              title={item.title}
              key={index}
            ></Box>
          </StyledAnchorLink>
        )
      })}
    </Section>
  )
}

export default WhatsYourStruggle
