import React from "react"
import Break from "../../shared/Break"
import PostPreview from "../../shared/PostPreview"
import Section from "../../shared/Section"
import StyledLink from "../../shared/StyledLink"

const CaseStudies = ({ caseStudies }) => {
  return (
    <Section
      bgImage={"images/stripes-light.png"}
      bgImageRepeat
      id="CaseStudies"
      isFullWidth
      subtitle=""
      title="Case Studies"
    >
      {caseStudies.map(({ node: caseStudy }) => (
        <div key={caseStudy.id}>
          <PostPreview
            title={caseStudy.title}
            logo={caseStudy.companyLogo}
            content={caseStudy.overview.trunc(200, true)}
            url={`/case-studies/${caseStudy.slug}`}
            publishDate={caseStudy.createdAt}
          />
        </div>
      ))}
      {caseStudies.length > 3 && (
        <>
          <Break />
          <StyledLink isPrimary to="/case-studies/">
            View More
          </StyledLink>
        </>
      )}
    </Section>
  )
}

export default CaseStudies
