import React from "react"
import "./style.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Spotlight = () => {
  const data = useStaticQuery(graphql`
    query {
      nascar: file(relativePath: { eq: "slider/nascar.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meteorAgency: file(relativePath: { eq: "slider/meteor-agency.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bestDesigns: file(relativePath: { eq: "slider/best-designs.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designRush: file(relativePath: { eq: "slider/design-rush.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Use "#" in link for no linked slide
  const slides = [
    {
      title: "Meteor Agency - A Boutique Digital Marketing Agency",
      fluid: data.meteorAgency.childImageSharp.fluid,
      link: "#",
      openLinkInNewTab: false,
    },
    {
      title: "Featured Client NASCAR",
      fluid: data.nascar.childImageSharp.fluid,
      link: "/case-studies/Optimization",
      openLinkInNewTab: false,
    },
    {
      title: "Meteor Agency - A Boutique Digital Marketing Agency",
      fluid: data.designRush.childImageSharp.fluid,
      link:
        "https://finance.yahoo.com/news/designrush-ranks-top-digital-marketing-115000828.html",
      openLinkInNewTab: true,
    },
    {
      title:
        "Recognized as one of the top social Media Marketing Agencies by BestDesigns.co",
      fluid: data.bestDesigns.childImageSharp.fluid,
      link:
        "https://finance.yahoo.com/news/9-best-facebook-video-ad-125000994.html",
      openLinkInNewTab: true,
    },
  ]

  const slideClicked = (slide) => {
    if (slides[slide].link !== "#") {
      window.open(
        slides[slide].link,
        slides[slide].openLinkInNewTab ? "_blank" : "_self"
      )
    }
  }

  return (
    <>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        stopOnHover={true}
        swipeable={true}
        onClickItem={(slide) => slideClicked(slide)}
      >
        {slides.map((slide, index) => {
          return (
            <div key={index}>
              <Img
                fluid={slide.fluid}
                alt={slide.title}
                key={index}
                className={slide.link !== "#" ? "hasLink" : ""}
              />
            </div>
          )
        })}
      </Carousel>
    </>
  )
}

export default Spotlight
