import React from "react"
import { colors } from "../../../assets/themes/default"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Section from "../../shared/Section"
import styled from "@emotion/styled"
import StyledLink from "../../shared/StyledLink"
import Text from "../../shared/Text"

const Awards = () => {
  const data = useStaticQuery(graphql`
    query {
      designRush2020: file(relativePath: { eq: "awards/designrush-2020.png" }) {
        childImageSharp {
          fluid(maxWidth: 72, maxHeight: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designRush2021: file(relativePath: { eq: "awards/designrush-2021.png" }) {
        childImageSharp {
          fluid(maxWidth: 72, maxHeight: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bestDesigns2020: file(
        relativePath: { eq: "awards/bestdesigns-2020.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 72, maxHeight: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const awards = [
    {
      title: "Best Digital Marketing Agency 2021",
      awardedBy: "DesignRush",
      fluid: data.designRush2021.childImageSharp.fluid,
      link:
        "https://finance.yahoo.com/news/designrush-ranks-top-digital-marketing-115000828.html",
      openLinkInNewTab: true,
    },
    {
      title: "Best Digital Marketing Agency 2020",
      awardedBy: "DesignRush",
      fluid: data.designRush2020.childImageSharp.fluid,
      link:
        "https://finance.yahoo.com/news/designrush-identifies-top-digital-marketing-111000979.html",
      openLinkInNewTab: true,
    },
    {
      title: "Recognized a one of the top Social Media Marketing Agencies",
      awardedBy: "BestDesigns.co",
      fluid: data.bestDesigns2020.childImageSharp.fluid,
      link:
        "https://finance.yahoo.com/news/9-best-facebook-video-ad-125000994.html",
      openLinkInNewTab: true,
    },
  ]

  const styleContainer = {
    display: "flex",
    flexDirection: "column",
    maxWidth: `80%`,
  }

  const styleRow = {
    alignItems: `center`,
    display: "flex",
    marginBottom: `20px`,
    width: `100%`,
  }

  const styleCell = {
    flex: `1 1 auto`,
    width: `72px`,
  }

  const Container = styled.div`
    ${styleContainer}
  `

  const Row = styled.div`
    ${styleRow}
  `

  const Cell = styled.div`
    ${styleCell}
  `

  return (
    <Section bgColor={colors.white} isFullWidth title="Awards">
      <Container>
        {awards.map((award) => {
          return (
            <Row key={award.title}>
              <Cell>
                <Img fluid={award.fluid} alt={award.title} key={award.title} />
              </Cell>
              <Cell style={{ width: `100%`, marginLeft: `20px` }}>
                <Text type="subsubtitle" noMargins>
                  {award.title}
                </Text>
                <Text type="xsmall" noMargins noBreak>
                  {award.awardedBy}
                </Text>
                <StyledLink
                  to={award.link}
                  isExternal
                  isSmall
                  notPadded
                  style={{ marginLeft: `10px` }}
                >
                  Read more
                </StyledLink>
              </Cell>
            </Row>
          )
        })}
      </Container>
    </Section>
  )
}

export default Awards
