import React from "react"
import { colors } from "../../../assets/themes/default"
import Section from "../../shared/Section"
import StyledLink from "../../shared/StyledLink"

const DesignRush = () => {
  const links = [
    {
      label: "Digital Marketing Companies",
      href: "https://topmarketingcompanies.com/digital-marketing-agencies/",
    },
    {
      label: "Digital Agencies",
      href: "https://topmarketingcompanies.com/digital-agencies/",
    },
    {
      label: "Facebook Marketing",
      href: "https://topmarketingcompanies.com/facebook-marketing-companies/",
    },
    {
      label: "Instagram Marketing",
      href: "https://topmarketingcompanies.com/instagram-marketing-companies/",
    },
    {
      label: "PPC Agencies",
      href: "https://topmarketingcompanies.com/ppc-agencies/",
    },
    {
      label: "Small Business Digital Marketing",
      href:
        "https://topmarketingcompanies.com/small-business-digital-marketing-agencies/",
    },
    {
      label: "B2B Digital Marketing",
      href:
        "https://topmarketingcompanies.com/b2b-digital-marketing-companies/",
    },
    {
      label: "Automotive Digital Marketing",
      href:
        "https://topmarketingcompanies.com/automotive-digital-marketing-agencies/",
    },
    {
      label: "Digital Marketing for Startups",
      href:
        "https://topmarketingcompanies.com/digital-marketing-agencies-for-startups/",
    },
    {
      label: "Digital Marketing Companies in US ",
      href:
        "https://topmarketingcompanies.com/united-states-digital-marketing-agencies/",
    },
  ]
  return (
    <Section
      bgColor={colors.blueLight}
      isFullWidth
      subtitle="We would like to thank DesignRush for featuring us as top development
        agency under the following categories:"
    >
      {links.map((link, index) => {
        return (
          <StyledLink to={link.href} isExternal key={index}>
            {link.label}
          </StyledLink>
        )
      })}
    </Section>
  )
}

export default DesignRush
