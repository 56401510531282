import React from "react"
import { colors } from "../../../assets/themes/default"
import Box from "../../shared/Box"
import Section from "../../shared/Section"
import styled from "@emotion/styled"
import StyledLink from "../../shared/StyledLink"
import Text from "../../shared/Text"

const WhatWeDo = () => {
  const styleWrapper = {
    backgroundColor: `${colors.white}`,
    border: `1px solid ${colors.grayLight}`,
    borderRadius: `10px`,
    color: `${colors.grayDark}`,
    marginBottom: `20px`,
    paddingBottom: `20px`,
    paddingTop: `30px`,
    textAlign: `center`,
    width: `100%`,
  }

  const styleIntro = {
    maxWidth: `80%`,
  }

  const styleColumns = {
    alignItems: `flex-start`,
    display: `flex`,
    flexBasis: `25%`,
    flexDirection: `row`,
    flexWrap: `wrap`,
    justifyContent: `space-around`,
    margin: `0 auto`,
    textAlign: `left`,
    "@media (max-width:800px)": {
      alignItems: `center`,
      flexDirection: `column`,
      width: `100%`,
    },
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const Intro = styled.div`
    ${styleIntro}
  `

  const Columns = styled.div`
    ${styleColumns}
  `

  return (
    <Section title="How We Can Help Your Business" id="WhatWeDo">
      <Intro>
        <Text type="intro">
          A combined decades of experience from our team in managing
          subscription memberships taught us the importance of putting our
          customers first. For over 15 years, we have developed acquisition
          techniques, engagement strategies and analytically driven insights to
          achieve exceptional results with an increased Return On Investment.
        </Text>
        <Text type="intro">
          We are results driven, let us put our expertise to work for you. We
          will create a strategy to come up with the best channels to optimize
          your results and achieve your goals.
        </Text>
      </Intro>
      <Wrapper id="WhatWeDoSocialMarketing" style={{ marginTop: `30px` }}>
        <Text type="subtitle" color={colors.blue} isBold noMargins>
          Social Marketing
        </Text>
        <Columns>
          <Box title="Social Media Management Services">
            <Text>
              We will assist you in navigating and making smart decisions
              regarding the focus of your media budget. With more than 10
              digital media platforms available, we will help determine the mix
              for your business’ goals and maximization of your overall return
              on investment.
            </Text>
          </Box>
          <Box title="Social Media Management (SMM)">
            <Text>
              Social media channels will be managed by experienced marketing
              professionals that will drive the best results, while keeping your
              audience engaged, focusing on your core business.
            </Text>
          </Box>
          <Box title="Search Engine Optimization">
            <Text>
              Visibility in organic listing is a key success factor for any
              business. Let us show you how you can succeed in this space.
            </Text>
          </Box>
        </Columns>
        <StyledLink
          hasShadow
          icon={`right-arrow-white`}
          isAlternative
          state={{ section: `Social` }}
          to={`/pricing`}
        >
          See our Plans
        </StyledLink>
      </Wrapper>

      <Wrapper
        id="WhatWeDoAnalytics"
        style={{ backgroundColor: colors.grayLightX }}
      >
        <Text type="subtitle" color={colors.blue} isBold noMargins>
          Analytics
        </Text>
        <Columns>
          <Box title="Full Campaign Optimization" isTransparent>
            <Text>
              Successful digital marketing and email campaigns rely on many
              factors working perfect together. The funnel to turning a lead
              into a conversion usually involves multiple steps and factors. A
              potential customer will view an ad, click, be sent to a landing
              page, review the materials, and then convert. While A/B testing is
              useful, it does not take the entire funnel into account. Factorial
              Design Experiments, on the other hand, provides the most optimized
              funnel and conversion path for users.
            </Text>
          </Box>
          <Box title="Fan Analytics" isTransparent>
            <Text>
              For over a decade, Meteor Affinity’s expertise in marketing
              optimization has helped bring sponsors and brands closer to their
              passionate fan-base, unlocking previously untapped potential.
              Working closely with NASCAR over the years, Meteor Affinity was
              able to increase fan engagement, while assisting many sponsors to
              better reach and engage with the NASCAR audience. Our history
              working with affinity groups has taught us the power a brand has
              and how to better the relationship between brand and individual
              using data.
            </Text>
          </Box>
          <Box title="Advanced Analytics" isTransparent>
            <Text>
              Knowing your audience is important; however, we take it a step
              further. Having knowledge of an individual's actions such as
              opens, clicks and purchase behavior, we are able to segment and
              target into specific groups, anticipating their future actions
              based on previous ones.
            </Text>
          </Box>
        </Columns>
        <StyledLink
          hasShadow
          icon={`right-arrow-white`}
          isAlternative
          state={{ section: `FCOServices` }}
          to={`/pricing`}
        >
          See our Plans
        </StyledLink>
      </Wrapper>

      <Wrapper id="WhatWeDoEmailMarketing">
        <Text type="subtitle" color={colors.blue} isBold noMargins>
          Email Marketing
        </Text>
        <Columns>
          <Box title="Access to our Databases">
            <Text>
              Having access to our affinity email database to send a precise
              message to a highly engaged group of people, coupled with our
              advanced segmentation and testing strategies increases the ROI for
              a single or an ongoing marketing campaign.
            </Text>
          </Box>
          <Box title="Managing other Databases">
            <Text>
              Using vast learnings from our years of managing our own robust
              database, we offer services to help optimize yours. From
              preventing list fatigue to helping achieve the best ROI for your
              own internal campaigns, Meteor Agency is here to help you achieve
              the best value for your database.
            </Text>
          </Box>
        </Columns>
        <StyledLink
          hasShadow
          icon={`right-arrow-white`}
          isAlternative
          state={{ section: `EmailMarketing` }}
          to={`/pricing`}
        >
          See our Plans
        </StyledLink>
      </Wrapper>

      <Wrapper
        id="WhatWeDoOtherCapabilities"
        style={{ backgroundColor: colors.grayLightX }}
      >
        <Text type="subtitle" color={colors.blue} isBold noMargins>
          Other Capabilities
        </Text>
        <Columns>
          <Box title="Lead Generation" isTransparent>
            <Text>
              With the goal of obtaining quality leads with a high chance of
              converting in the future, we use years of experience to optimize
              the lead generation process. Several approaches including
              giveaways, targeted lead social campaigns, and remarketing efforts
              to help to obtain an optimized group of qualified leads likely to
              convert.
            </Text>
          </Box>
        </Columns>
        <Columns>
          <Box title="Video" isTransparent>
            <Text>
              Online video consumption continues to grow each year and shows no
              signs of slowing down. Video is a great platform to get your
              message across, and technology has removed many barriers to
              achieving high production value. With our production and editing
              experts, and our ability to incorporate 2D/3D motion graphics, we
              get the tough parts done for you. Our team of experienced video
              producers and writers love collaborating and creating the perfect
              script to tell your unique story.
            </Text>
          </Box>
          <Box title="Content Marketing" isTransparent>
            <Text>
              A content program plays a crucial role in driving brand awareness
              and trust, it's also a key part of improving your search engine
              optimization. Our team uses an SEO-based research process to build
              your program from scratch or improve your existing content in
              order to ensure that it is clear, relevant and keyword-rich.
              Whether it's through blogs, video, social media or podcasts, your
              entire content program should be designed to attract and engage
              your target audience and drive them to take action.
            </Text>
          </Box>
        </Columns>
        <Columns>
          <Box title="Full Web Design" isTransparent>
            <Text>
              According to a Nielsen study, 56% of people would not trust a
              company that did not have a website. Having a website instantly
              boosts your credibility as a legitimate business. Going further, a
              well-designed site will determine within seconds whether a user
              leaves your site or if they find what they are looking for and
              take action. Our design team combines the fundamentals of branding
              and aesthetics with a focus on usability. When building or
              redesigning a new site, we'll map out the site's objectives
              upfront so both teams are on the same page.
            </Text>
            <Text>
              All of our websites are custom-designed, meaning we won't box you
              into any proprietary content management systems unless required or
              recommended. Additionally, all of our websites are built to look
              and perform beautifully on every screen shape and size.
            </Text>
          </Box>
          <Box title="Branding + Design" isTransparent>
            <Text>
              Consistent, reliable, professional - these are the attributes of
              strong brands. Branding is the emotion someone feels when they
              think about your business. Creating that emotion takes more than
              just talented designers. Our team will work to develop or
              strengthen your overall brand. We take the time to review the
              competitive landscape, research your desired audience and solidify
              your message to ensure your identity is in line with your
              audience's expectations.
            </Text>
          </Box>
        </Columns>
        <Columns>
          <StyledLink
            hasShadow
            icon={`right-arrow-white`}
            isAlternative
            to={`/sign-up`}
          >
            Get a Quote
          </StyledLink>
        </Columns>
      </Wrapper>
    </Section>
  )
}

export default WhatWeDo
