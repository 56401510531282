import React from "react"
import { graphql } from "gatsby"
import Awards from "../components/index/Awards"
import Calendly from "../components/index/Calendly"
import CaseStudies from "../components/index/CaseStudies"
import Clients from "../components/index/Clients"
import DesignRush from "../components/index/DesignRush"
import FromTheBlog from "../components/index/FromTheBlog"
import Layout from "../components/layout"
import SEO from "../components/shared/SEO"
import Spotlight from "../components/index/Spotlight"
import WhatsYourStruggle from "../components/index/WhatsYourStruggle"
import WhatWeDo from "../components/index/WhatWeDo"
import WhyUs from "../components/index/WhyUs"

const IndexPage = ({ data }) => {
  const blogPosts = data.allContentfulBlogPost.edges
  const caseStudies = data.allContentfulCaseStudy.edges
  return (
    <Layout>
      <SEO
        title="Meteor Affinity"
        description="A Boutique Digital Marketing Agency"
        keywords={[
          `marketing`,
          `digital`,
          `email`,
          `SEO`,
          `search engine optimization`,
          `campaigns`,
          `analytics`,
          `databases`,
        ]}
        pagePath="/"
        schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Meteor Affinity",
              }
            }`}
      />
      <Spotlight />
      <WhatsYourStruggle />
      <WhatWeDo />
      <WhyUs />
      <FromTheBlog blogPosts={blogPosts} />
      <CaseStudies caseStudies={caseStudies} />
      <Clients />
      <Calendly />
      <Awards />
      <DesignRush />
    </Layout>
  )
}
export default IndexPage

// HACK HACKITY HACK!: To allow non-required fields in contentful, I've created
// a `dummy-content` page with all the fields filled. This way, Gatsby doesn't
// fail when retrieving empty fields. That's why I filter the content with the
// slug `dummy-content`.
export const query = graphql`
  {
    allContentfulBlogPost(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          body {
            body
            childMarkdownRemark {
              plainText
            }
          }
          image {
            fluid(maxWidth: 350, quality: 98) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags
          createdAt
        }
      }
    }
    allContentfulCaseStudy(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
      filter: { slug: { ne: "dummy-content" } }
    ) {
      edges {
        node {
          id
          title
          companyLogo {
            fluid(maxWidth: 350, quality: 98) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          slug
          overview
          tags
          createdAt
        }
      }
    }
  }
`
