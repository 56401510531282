import React from "react"
import { colors } from "../../../assets/themes/default"
import styled from "@emotion/styled"
import Text from "../Text"

const Box = ({
  bgColor,
  bgColorHighlight,
  children,
  description,
  hasShadow,
  image,
  isRounded,
  isTransparent,
  textColor,
  title,
}) => {
  let styleWrapper = {
    backgroundColor: bgColor ? bgColor : `${colors.white}`,
    flexBasis: `28%`,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: `space-around`,
    margin: `25px`,
    minHeight: `290px`,
    minWidth: `250px`,
    maxWidth: `550px`,
    transition: `all 0.3s`,
    "&:hover": {
      backgroundColor: bgColorHighlight ? bgColorHighlight : ``,
    },
    "@media (max-width:800px)": {
      maxWidth: `550px`,
    },
  }

  if (isRounded) {
    styleWrapper = {
      ...styleWrapper,
      borderRadius: `20px`,
    }
  }

  if (hasShadow) {
    styleWrapper = {
      ...styleWrapper,
      boxShadow: `0px 20px 50px rgba(0, 0, 0, 0.5)`,
    }
  }

  if (isTransparent) {
    styleWrapper = {
      ...styleWrapper,
      backgroundColor: `transparent`,
    }
  }

  const stylePicture = {
    backgroundImage: `url("images/${image}.jpg")`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    color: `${colors.white}`,
    height: `130px`,
  }

  const styleTitle = {
    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,
    minHeight: `100px`,
    padding: `10px`,
    textAlign: `center`,
  }

  const styleMessage = {
    alignItems: `start`,
    display: `flex`,
    justifyContent: `center`,
    minHeight: `210px`,
    padding: `20px`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `
  const Picture = styled.div`
    ${stylePicture}
  `

  const Title = styled(Text)`
    ${styleTitle}
  `

  const Message = styled.div`
    ${styleMessage}
  `

  return (
    <Wrapper>
      {image && <Picture />}
      {title && (
        <Title
          type="subsubtitle"
          style={{ marginBottom: `0px` }}
          isBold
          color={textColor ? textColor : `${colors.black}`}
        >
          {title}
        </Title>
      )}
      {description && (
        <Message>
          <Text color={textColor ? textColor : `${colors.black}`}>
            {description}
          </Text>
        </Message>
      )}
      {children}
    </Wrapper>
  )
}

export default Box
