import React from "react"
import Section from "../../shared/Section"
import Image from "../../shared/Image"

const Clients = () => {
  return (
    <Section
      title="Our decades of experience have been trusted by brands such as"
      id="Clients"
    >
      <Image src="images/client-nascar.gif" />
      <Image src="images/client-chevrolet.gif" />
      <Image src="images/client-nationwide.gif" />
      <Image src="images/client-ipsos.gif" />
      <Image src="images/client-ecp.gif" />
    </Section>
  )
}

export default Clients
