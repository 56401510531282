import React from "react"
import { colors } from "../../../assets/themes/default"
import Section from "../../shared/Section"
import Box from "../../shared/Box"

const WhyUs = () => {
  return (
    <Section
      title="Why Us?"
      subtitle="Meteor Affinity consists of a small team that adapts quickly to the needs of our clients and marketing industry, in general. We pride ourselves on the past successes, lessons learned and tenured experience, using it to approach the continually changing landscape with tenacity."
      bgColor={colors.grayLightX}
      bgImage="images/stairs.jpg"
      id="WhyUs"
      isFullWidth
    >
      <Box
        title="Affinity Groups"
        description="15 years of experience using highly targeted methods to identify, and monetize fans from any affinity groups."
        hasShadow
      ></Box>
      <Box
        title="Flat fees &amp; No contracts"
        description="Flat, predictable fees with no long term commitments."
        hasShadow
      ></Box>
      <Box
        title="Experienced Marketers"
        description="You will be working with people with at least a decade of marketing experience!"
        hasShadow
      ></Box>
      <Box
        title="You own your Data"
        description="Any insights and/or metrics obtained from your marketing campaign will be able to go with you wherever you go."
        hasShadow
      ></Box>
      <Box
        title="Industry Expertise"
        description="Our team has more than 15 years of sports marketing expertise including facilitating and managing sports affinity groups, along with acquiring almost one million members."
        hasShadow
      ></Box>
      <Box
        title="All-in-One"
        description="Under one roof, our creative team resides along with an in house call center and fulfillment center allowing us to meet the needs of any campaign."
        hasShadow
      ></Box>
    </Section>
  )
}

export default WhyUs
