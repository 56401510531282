import React, { useEffect } from "react"
import { colors } from "../../../assets/themes/default"
import Section from "../../shared/Section"

const Calendly = React.memo((props) => {
  useEffect(() => {
    window.addEventListener &&
      window.addEventListener(
        "message",
        function (event) {
          if (event.origin === "https://nicholasdavid.youcanbook.me") {
            document.getElementById("ycbmiframenicholasdavid").style.height =
              event.data + "px"
          }
        },
        false
      )
  }, [])

  const sectionStyle = {
    backgroundBlendMode: "screen",
  }

  const iframeStyle = {
    backgroundColor: `transparent`,
    border: `0px`,
    height: `700px`,
    width: `90%`,
  }

  return (
    <Section
      title="Get A Free Marketing Consultation"
      subtitle="Schedule a 20 minutes call so you can learn more about your business and marketing needs."
      id="Calendly"
      color={colors.grayDark}
      bgColor={colors.blue}
      bgImage={"images/stairs.jpg"}
      isFullWidth
      style={sectionStyle}
    >
      <iframe
        src="https://nicholasdavid.youcanbook.me/?noframe=true&skipHeaderFooter=true"
        id="ycbmiframenicholasdavid"
        style={iframeStyle}
        frameBorder="0"
        allowtransparency="true"
        title={`youCanBookMe`}
      ></iframe>
    </Section>
  )
})

export default Calendly
